@tailwind base;
@tailwind components;
@tailwind utilities;

.slick-dots {
    @apply mt-1
}

.slick-dots li {
    @apply !w-auto !h-auto;
}

.slick-dots li.slick-active div {
    @apply !bg-[#022883]
}

@layer components {
    .scrollbar::-webkit-scrollbar {
        width: 15px;
        height: 15px;
    }

    .scrollbar::-webkit-scrollbar-track {
        border-radius: 100vh;
        background: #f7f4ed;
    }

    .scrollbar::-webkit-scrollbar-thumb {
        background: #e0cbcb;
        border-radius: 100vh;
        border: 3px solid #f6f7ed;
    }

    .scrollbar::-webkit-scrollbar-thumb:hover {
        background: #c0a0b9;
    }
}